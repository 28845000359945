import { defineStore } from "pinia";
import {
  loginByEmail,
  forgotPassword,
  resetNewPassword,
  getUserInfo,
  getChartAuthToken,
} from "@/api/login";
import { useProfileFormStore } from "@/stores/accountManagement.store";
import { useAllotmentStore } from "@/stores/allotment.store";
import { usePackageStore } from "@/stores/package.store";
import { useVoucherStore } from "@/stores/voucher.store";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    token: "",
    role: "",
    user: "",
    name: "",
    // avatar: "",
    // code: "",
    restaurantID: "",
    restaurantName: "",
    outletType: "restaurant",
    restaurantInfo: null,
    chartToken: null,
    restaurants: [],
    loginAs: "",
    userId: "",
  }),
  getters: {
    getToken: (state) => state.token,
    getRole: (state) => state.role,
    getName: (state) => state.name,
    getUser: (state) => state.user,
    getAvatar: (state) => state.avatar,
    getRestaurantName: (state) => state.restaurantName,
    getRestaurantID: (state) => state.restaurantID,
    getOutletType: (state) => state.outletType,
    restaurantsIsEmpty: (state) => state.restaurants.length === 0,
    loginAsGroup: (state) => state.loginAs === "group",
    getRestaurants: (state) => state.restaurants,
  },
  actions: {
    loginByEmail(payload) {
      return new Promise((resolve, reject) => {
        loginByEmail(payload.payload)
          .then((response) => {
            this.token = response.data.attributes.jwt.token;
            this.loginAs = payload.loginAs;
            this.storeUser(response.data.attributes);
            // this.outletType = response.data.attributes.outletType;
            // this.avatar = response.attributes.avatar;
            // this.code = response.data.attributes.code;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    storeUser(info) {
      this.role = info.role;
      this.user = info;
      this.name = info.name;
      this.restaurantName = info.restaurant.name;
      this.restaurantID = info.restaurant.id;
      this.outletType = info.restaurant.outlet_type || "restaurant";
      if (this.loginAs === "group") {
        this.restaurants = info.restaurants;
      } else {
        this.restaurantInfo = info.restaurant;
      }
    },

    getUserInfo(payload) {
      return new Promise((resolve, reject) => {
        getUserInfo(payload)
          .then((response) => {
            if (!response.data?.attributes) {
              reject({
                msg: "User not found",
                code: 400,
              });

              return false;
            }

            this.token = payload.token;
            this.userId = response.data.id;
            this.storeUser(response.data.attributes);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    forgotPassword(payload) {
      return new Promise((resolve, reject) => {
        forgotPassword(payload)
          .then((response) => resolve(response))
          .catch((err) => {
            reject(err);
          });
      });
    },

    resetNewPassword(payload) {
      return new Promise((resolve, reject) => {
        resetNewPassword(payload)
          .then((response) => resolve(response))
          .catch((err) => {
            reject(err?.response?.data);
          });
      });
    },

    getChartAuthToken(payload) {
      return new Promise((resolve, reject) => {
        getChartAuthToken(payload)
          .then((response) => {
            if (response?.success === false) {
              throw response;
            }

            this.chartToken = response.token;
            resolve(response);
          })
          .catch((err) => {
            reject(err?.response?.data);
          });
      });
    },

    logOut() {
      const accountStore = useProfileFormStore();
      const alotmentStore = useAllotmentStore();
      const packageStore = usePackageStore();
      const voucherStore = useVoucherStore();

      try {
        this.token = "";
        this.role = "";
        this.user = "";
        this.name = "";
        this.restaurantName = "";
        this.restaurantID = "";
        this.outletType = "restaurant";
        this.restaurantInfo = null;
        this.restaurants = [];
        this.loginAs = "";
        accountStore.$reset();
        packageStore.$reset();
        alotmentStore.$reset();
        voucherStore.$reset();
        // window.location.href = "/";
      } catch (err) {
        console.warn("[pinia.user] LogOut", err);
      }
    },
  },
});
